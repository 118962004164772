<template>
    <div class="rb-view">
        <el-form :model="form_data" :ref="form_name" :rules="form_rules" size="mini" label-width="200px" style="max-width: 800px" inline>
            <el-row>
                <el-col>
                    <el-form-item label="试玩核销可获运动量:" prop="play_experience_verify_score" required>
                        <el-input-number v-model="form_data.play_experience_verify_score" :min="0"></el-input-number>
                        <div class="rb-input-tip">核销即时加分</div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="散场核销可获运动量:" prop="play_single_verify_score" required>
                        <el-input-number v-model="form_data.play_single_verify_score" :min="0"></el-input-number>
                        <div class="rb-input-tip">场次结束后加分</div>
                    </el-form-item>
                    <el-form-item label="散场核销可获运动量 - 会员:" prop="play_single_verify_score" required>
                        <el-input-number v-model="form_data.play_single_verify_score_vip" :min="0"></el-input-number>
                        <div class="rb-input-tip">场次结束后加分</div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="包场核销可获运动量" prop="play_team_verify_score" required>
                        <el-input-number v-model="form_data.play_team_verify_score" :min="0"></el-input-number>
                        <div class="rb-input-tip">场次结束后加分</div>
                    </el-form-item>
                    <el-form-item label="包场核销可获运动量 - 会员" prop="play_team_verify_score_vip" required>
                        <el-input-number v-model="form_data.play_team_verify_score_vip" :min="0"></el-input-number>
                        <div class="rb-input-tip">场次结束后加分</div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="进阶课核销可获运动量" prop="play_coach_verify_score" required>
                        <el-input-number v-model="form_data.play_coach_verify_score" :min="0"></el-input-number>
                        <div class="rb-input-tip">场次结束后加分</div>
                    </el-form-item>
                </el-col>
            </el-row>


            <div style="display:flex;flex-direction:row;justify-content:flex-end;width:100%;">
                <div style="float:right;">
                    <el-button @click="handleSubmitTap(form_name)" size="mini" type="primary">保存</el-button>
                </div>
            </div>

        </el-form>


    </div>
</template>

<script>
    import {getMatchSeasonSetting,setMatchSeasonSetting} from "@/api/hado/setting";

    export default {
        name: "match-season-setting",
        data(){
            return{
                form_name:'match_season_setting_form',
                form_data:{},
                form_rules:{
                    play_experience_verify_score:[{required:true,message:'请输入试玩核销可获运动量',trigger:'change'}],
                    play_single_verify_score:[{required:true,message:'请输入散场核销可获运动量',trigger:'change'}],
                    play_single_verify_score_vip:[{required:true,message:'请输入会员散场核销可获运动量',trigger:'change'}],
                    play_team_verify_score:[{required:true,message:'请输入包场核销可获运动量',trigger:'change'}],
                    play_team_verify_score_vip:[{required:true,message:'请输入会员包场核销可获运动量',trigger:'change'}],
                    play_coach_verify_score:[{required:true,message:'请输入进阶课核销可获运动量',trigger:'change'}]
                },
            }
        },
        created(){
            this.initPage()
        },
        methods:{
            initPage(){
                getMatchSeasonSetting().then(resp=>{
                    console.log('resp:',resp)
                    this.form_data = resp.data
                })
            },
            reloadPage(){
                this.initPage()
            },
            handleSubmitTap(form_name) {
                this.$refs[form_name].validate((valid)=>{
                    if(valid){
                        setMatchSeasonSetting(this.form_data).then(()=>{
                            this.reloadPage()
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .rb-input-tip{
        color: #969799;
        line-height: 14px;
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 0;
    }
</style>
